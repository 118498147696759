import React from 'react'
import ReactDOM from 'react-dom/client'
import { Route, Routes, BrowserRouter } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import ListaNFe from './pages/ListaNFe';
import ListaNFCe from './pages/ListraNFCe';
import ListaCFe from './pages/ListaCFE';
import * as Sentry from "@sentry/react";
import ListaNFSe from './pages/ListaNFSe';

Sentry.init({
  dsn: "https://d2edfc24733b86eda7f9ff79134509b3@o4507822907457536.ingest.us.sentry.io/4507826690260992",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      enableScreenshot: false,
      isEmailRequired: true,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/contador\.hostmundo\.net/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    
    <BrowserRouter>
      <Routes>
        <Route element = { <Home/> }  path="/" />
        <Route element = { <NotFound /> } path='*' exact />

        <Route element = { <Login/> }  path="/login"/>
        <Route element = { <ListaCFe/> }  path="/listacfe" />
        <Route element = { <ListaNFe/> }  path="/listanfe" />
        <Route element = {<ListaNFSe/>} path='/listanfse' />
        <Route element = { <ListaNFCe/> }  path="/listanfce" />

      </Routes>
    </BrowserRouter>

  </React.StrictMode>,
)
